export const clientNameFooter = "SANGHA YOGA";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1.77'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "MARCELA FREITAS MONTEIRO";
export const nomeFornecedor = "SANGHA YOGA";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [];

export const drawerClosedLinks = [
  // {
  //   title: "Fale Comigo",
  //   link: "https://instagram.com",
  // },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = true;
export const drawerOpenedLinks = [
  {
      title: "Assinar Planos",
      link: "https://sanghayoga.sanghaoficial.com/",
    },
  ];
